import React from 'react';
import { Switch } from 'react-router-dom';
import { Create } from '../Pages/Create/Create';
import { Edit } from '../Pages/Edit/Edit';
import { Home } from '../Pages/Home/Home';
import { Route } from './Route';

const Routes: React.FC = () => (
	<Switch>
		<Route path="/" exact component={Home} />
		<Route path="/create" isAuthenticated component={Create} />
		<Route path="/edit" isAuthenticated component={Edit} />
	</Switch>
);

export default Routes;
