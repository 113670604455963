import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { MdBlock } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { Container, InnerContainer, Error } from './InputStyle';
// @ts-ignore
import IntlCurrencyInput from 'react-intl-currency-input';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label?: string;
	mask?: string;
	isMoney?: boolean;
	onChangeText?: (value: string) => void;
}

const currencyConfig = {
	locale: 'pt-BR',
	formats: {
		number: {
			BRL: {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
		},
	},
};

export const Input: React.FC<InputProps> = ({
	name,
	label,
	mask,
	disabled,
	isMoney,
	onChangeText,
	...rest
}) => {
	const inputRef = useRef(null);

	const [isFocused, setIsFocused] = useState(false);

	const {
		fieldName,
		defaultValue,
		registerField,
		error,
		clearError,
	} = useField(name);

	const handleInputFocus = () => setIsFocused(true);

	const handleInputBlur = () => setIsFocused(false);

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: inputRef.current,
			path: 'value',
		});
	}, [fieldName, registerField]);

	return (
		<Container>
			{!!label && <h4>{label}</h4>}

			<InnerContainer
				isFocused={isFocused}
				isErrored={!!error}
				isDisabled={!!disabled}
			>
				{isMoney ? (
					<IntlCurrencyInput
						defaultValue={defaultValue}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						disabled={!!disabled}
						currency="BRL"
						config={currencyConfig}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>,
							value: string,
						) => {
							event.preventDefault();

							clearError();

							if (onChangeText) {
								onChangeText(value);
							}
						}}
						{...rest}
					/>
				) : mask ? (
					<InputMask
						ref={inputRef}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						mask={mask}
						// @ts-ignore
						maskChar={''}
						disabled={!!disabled}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>,
						) => {
							event.preventDefault();

							clearError();

							if (onChangeText) {
								onChangeText(event.target.value);
							}
						}}
						{...rest}
					/>
				) : (
					<input
						ref={inputRef}
						defaultValue={defaultValue}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						disabled={!!disabled}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>,
						) => {
							event.preventDefault();

							clearError();

							if (onChangeText) {
								onChangeText(event.target.value);
							}
						}}
						{...rest}
					/>
				)}

				{error && (
					<Error title={error}>
						<FiAlertCircle size={20} color="#eb0505" />
					</Error>
				)}

				{disabled && <MdBlock size={20} color="#c9c9c9" />}
			</InnerContainer>
		</Container>
	);
};
