import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input } from '../../Components/Input/Input';
import { Invoice, ListItem } from '../../Components/ListItem/ListItem';
import { useAuth } from '../../Hooks/AuthContext';
import { useToast } from '../../Hooks/ToastContext';
import { api } from '../../Services/api';
import { getErrorMessage } from '../../Utils/ErrorUtils';
import {
	ArrowButtons,
	BottomContainer,
	Container,
	InnerContainer,
	PageButtons,
	TopContainer,
} from './HomeStyle';

interface InvoicesResponse {
	data: Invoice[];
	count: number;
}

const LIMIT_OF_INVOICES_BY_PAGE = 10;

export const Home: React.FC = () => {
	const [invoices, setInvoices] = useState<InvoicesResponse>({
		data: [],
		count: 0,
	});
	const [page, setPage] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [search, setSearch] = useState('');

	const { addToast } = useToast();
	const { user, token } = useAuth();

	const history = useHistory();

	const loadInvoices = async () => {
		try {
			const response = await api.post(
				'/invoices/list',
				{
					search: search.toLowerCase(),
					page,
					limit: LIMIT_OF_INVOICES_BY_PAGE,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			setInvoices(response.data);
		} catch (error) {
			addToast({
				type: 'error',
				title: 'Erro',
				description: getErrorMessage(error),
			});
		}
	};

	useEffect(() => {
		const numberOfPages =
			invoices.count === LIMIT_OF_INVOICES_BY_PAGE
				? 1
				: Math.floor(invoices.count / LIMIT_OF_INVOICES_BY_PAGE) + 1;
		setTotalPages(numberOfPages);
	}, [invoices.count]);

	useEffect(() => {
		if (!!user) {
			loadInvoices();
		}
	}, [user, token, page, search]);

	return (
		<Container>
			<Form onSubmit={() => {}}>
				<InnerContainer>
					<TopContainer>
						<Input
							name="search"
							placeholder="Pesquisar"
							onChange={(value) => setSearch(value.target.value)}
						/>
						<button onClick={() => history.push('/create')}>
							Cadastrar
						</button>
					</TopContainer>

					{invoices.data.map((invoice) => (
						<ListItem
							key={invoice.id}
							invoice={invoice}
							onDelete={loadInvoices}
						/>
					))}

					{page !== 0 || totalPages !== 1 ? (
						<BottomContainer>
							<ArrowButtons
								disabled={page === 0}
								onClick={() => setPage((state) => state - 1)}
							>
								{'Anterior'}
							</ArrowButtons>
							<ArrowButtons
								disabled={page === totalPages - 1}
								onClick={() => setPage((state) => state + 1)}
							>
								{'Próximo'}
							</ArrowButtons>
						</BottomContainer>
					) : (
						<></>
					)}
				</InnerContainer>
			</Form>
		</Container>
	);
};
