import styled, { css } from 'styled-components';

interface ContainerProps {
	loading: boolean;
}

export const Container = styled.div<ContainerProps>`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    display: none;
    justify-content: center;
    align-items: center;

	${({ loading }) =>
		loading &&
		css`
			display: flex;
		`};
`;
