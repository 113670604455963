import React from 'react';
import { Container } from './TooltipStyle';

interface TooltipProps {
	title: string;
	className?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
	title,
	className = '',
	children,
}) => (
	<Container className={className}>
		{children}
		<span>{title}</span>
	</Container>
);
