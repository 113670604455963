import { shade } from 'polished';
import styled from 'styled-components';
import { Colors } from '../../Styles/Colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
`;

export const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 112rem;
	padding: 4rem;
	margin: 8rem 0;
	border-radius: 0.4rem;
	background-color: ${Colors.basicWhite};
	box-shadow: 0 0.8rem 2rem -0.8rem black;
`;

export const NameContainer = styled.div`
	display: flex;
	width: 100%;

	div {
		& + div {
			margin: 0 0 0 2rem;
			width: 17.5%;
		}
	}
`;

export const AddressContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 0.8rem;

	div {
		width: 20%;

		& + div {
			margin: 0 0 0 2rem;
			width: 100%;
		}
	}
`;

export const CityContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 0.8rem;

	div {
		& + div {
			margin: 0 0 0 2rem;
		}
	}
`;

export const DatesContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 0.8rem;

	div {
		& + div {
			margin: 0 0 0 2rem;
		}
	}
`;

export const ItemsTitle = styled.h2`
	margin: 2.8rem 0 0.8rem 0;
	color: ${Colors.black};
	font-size: 2rem;
	font-weight: normal;
`;

export const ItemContainer = styled.div`
	display: flex;
	margin-top: 0.8rem;
	align-items: center;

	div {
		& + div {
			margin-left: 2rem;
		}
	}

	svg {
		width: 4rem;
		height: 4rem;
		color: ${Colors.black};
		margin: 1.2rem 0 0 2rem;
		cursor: pointer;
	}
`;

export const AddItem = styled.div`
	display: flex;
	align-items: center;
	padding: 0.8rem;
	margin-top: 1.6rem;
	width: fit-content;
	cursor: pointer;

	h2 {
		font-size: 1.6rem;
		font-weight: normal;
		color: ${Colors.black};
	}

	svg {
		width: 2rem;
		height: 2rem;
		color: ${Colors.black};
		margin-left: 0.8rem;
	}

	:hover {
		h2 {
			color: ${Colors.blue};
		}

		svg {
			color: ${Colors.blue};
		}
	}
`;

export const Comments = styled.textarea`
	resize: none;
	white-space: pre-wrap;
	margin-top: 2rem;
	padding: 1.2rem;
	height: 16rem;
	border: 0.2rem solid ${Colors.gray};
	border-radius: 0.5rem;

	font-family: Roboto, sans-serif;
	font-size: 1.4rem;

	:focus {
		border-color: ${Colors.blue};
	}
`;

export const NumberContainer = styled.div`
	margin-top: 0.8rem;
`;

export const SubmitButton = styled.button`
	background-color: ${Colors.yellow};
	color: ${Colors.black};
	width: 8rem;
	padding: 0.8rem;
	border: 0.1rem solid ${Colors.black};
	border-radius: 0.4rem;
	margin-top: 2rem;

	:hover {
		background-color: ${shade(0.05, Colors.yellow)};
	}

	:active {
		background-color: ${shade(0.1, Colors.yellow)};
	}
`;
