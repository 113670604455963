import { createStore } from 'redux';

interface ActionData {
  type: any;
}

interface InitialStateData {}

const INITIAL_STATE: InitialStateData = {};

const reducer = (state = INITIAL_STATE, action: ActionData) => state;

const store = createStore(reducer);

export default store;
