import { shade } from 'polished';
import styled, { css } from 'styled-components';
import { Colors } from '../../Styles/Colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
`;

export const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 1120px;
	padding: 4rem;
	margin: 8rem 0;
	border-radius: 0.4rem;
	background-color: ${Colors.basicWhite};
	box-shadow: 0 0.8rem 2rem -0.8rem black;
	
	@media(max-width: 1200px) {
		width: 800px;
	}
`;

export const TopContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-radius: 0.4rem;
	margin-bottom: 4rem;
	align-items: center;
	justify-content: space-between;

	button {
		border: none;
		border-radius: 0.4rem;
		font-size: 1.4rem;
		font-weight: bold;
		color: ${Colors.black};
		background-color: ${Colors.yellow};
		width: 10.4rem;
		height: 4rem;
		margin-left: 2rem;
	}
`;

export const BottomContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-radius: 0.4rem;
	margin-top: 4rem;
	align-items: center;
	justify-content: flex-end;
`;

export const ArrowButtons = styled.button`
	border: none;
	font-size: 1.6rem;
	font-weight: bold;
	color: ${Colors.blue};
	background-color: ${Colors.basicWhite};
	user-select: none;
	padding: 0.4rem;

	& + button {
		margin-left: 0.4rem;
	}

	:hover {
		color: ${shade(0.3, Colors.blue)};
	}

	:active {
		color: ${shade(0.7, Colors.blue)};
	}

	:disabled {
		color: ${Colors.gray};
	}
`;

interface PageButtonsData {
	isFocused: boolean;
}

export const PageButtons = styled.button<PageButtonsData>`
	border: 0.2rem solid transparent;
	font-size: 1.6rem;
	font-weight: bold;
	color: ${Colors.blue};
	background-color: ${Colors.basicWhite};
	user-select: none;
	padding: 0.4rem 0.8rem;

	& + button {
		margin-left: 0.4rem;
	}

	${(props) =>
		props.isFocused &&
		css`
			border-bottom: 0.2rem solid ${Colors.blue};
		`}

	:hover {
		color: ${shade(0.3, Colors.blue)};
	}

	:active {
		color: ${shade(0.7, Colors.blue)};
	}
`;
