import { formatToBRL } from 'brazilian-values';
import { format, utcToZonedTime } from 'date-fns-tz';
import jsPDF from 'jspdf';
import { useState } from 'react';
import { AiOutlineClose, AiOutlineFilePdf } from 'react-icons/ai';
import Fatura from '../../Assets/fatura.jpeg';
import { useToast } from '../../Hooks/ToastContext';
import { Invoice } from '../ListItem/ListItem';
import { Loading } from '../Loading/Loading';
import { PdfFile } from '../PdfFIle/PdfFile';
import {
	ButtonsContainer,
	Container,
	InformationsContainer,
	InnerContainer,
	InvoiceNumber,
	ItemsContainer,
	RowContainer,
} from './InvoicePdfStyle';

interface InvoicePdfProps {
	invoice: Invoice;
	onCancel: () => void;
}

export const InvoicePdf = ({ invoice, onCancel }: InvoicePdfProps) => {
	const [loading, setLoading] = useState(false);

	const { addToast } = useToast();

	const downloadPDF = async () => {
		const pdf = new jsPDF('portrait', 'px', 'a4', true);
		try {
			setLoading(true);
			const pdfElement = window.document.getElementById('pdf');
			await pdf.html(pdfElement || '', {
				html2canvas: {
					scale: 0.55,
					x: -19,
					y: -27,
				},
			});
			pdf.save(invoice.client);
		} catch (error) {
			addToast({
				type: 'error',
				title: 'Ocorreu um erro ao baixar o arquivo.',
				description: 'Por favor, tente novamente.',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Loading loading={loading} />

			<Container>
				<ButtonsContainer>
					<div onClick={onCancel}>
						<AiOutlineClose />
						<h1>Cancelar</h1>
					</div>
					<div onClick={downloadPDF}>
						<AiOutlineFilePdf />
						<h1>Salvar PDF</h1>
					</div>
				</ButtonsContainer>

				<InnerContainer>
					<img src={Fatura} alt="Fatura" />

					<InvoiceNumber>{invoice.number}</InvoiceNumber>

					<InformationsContainer>
						<RowContainer style={{ marginTop: 0 }}>
							<h1 style={{ width: 408 }}>{invoice.client}</h1>
							<h1 style={{ marginLeft: 18 }}>{invoice.cnpj}</h1>
						</RowContainer>

						<RowContainer style={{ marginTop: 26 }}>
							<h1 style={{ width: 480 }}>{invoice.address}</h1>
							<h1 style={{ marginLeft: 18 }}>{invoice.CEP}</h1>
						</RowContainer>

						<RowContainer style={{ marginTop: 26 }}>
							<h1 style={{ width: 248 }}>{invoice.city}</h1>
							<h1
								style={{
									width: 212,
									marginLeft: 22,
								}}
							>
								{invoice.state}
							</h1>
							<h1 style={{ marginLeft: 14 }}>
								{format(
									utcToZonedTime(
										invoice.issue_date,
										'Europe/Portugal',
									),
									'dd/MM/yyyy',
									{
										timeZone: 'America/Sao_Paulo',
									},
								)}
							</h1>
						</RowContainer>

						<ItemsContainer>
							{invoice.items &&
								invoice.items.map((item) => (
									<RowContainer style={{ marginTop: 10 }}>
										<h1
											style={{
												width: 512,
											}}
										>
											{item.description}
										</h1>
										<h1
											style={{
												marginLeft: 22,
												width: 168,
											}}
										>
											{formatToBRL(item.value)}
										</h1>
									</RowContainer>
								))}
						</ItemsContainer>

						<RowContainer style={{ marginTop: 50 }}>
							<h1 style={{ width: 172, textAlign: 'center' }}>
								{format(
									utcToZonedTime(
										invoice.due_date,
										'Europe/Portugal',
									),
									'dd/MM/yyyy',
									{
										timeZone: 'America/Sao_Paulo',
									},
								)}
							</h1>
							<h1
								style={{
									width: 324,
									marginLeft: 22,
									textAlign: 'center',
								}}
							>
								{invoice.paymentConditions}
							</h1>
							<h1
								style={{
									marginLeft: 18,
									width: 172,
									textAlign: 'center',
								}}
							>
								{formatToBRL(invoice.totalValue)}
							</h1>
						</RowContainer>

						<h2
							style={{
								marginTop: 20,
								width: 702,
								height: 176,
							}}
						>
							{invoice.description}
						</h2>
					</InformationsContainer>
				</InnerContainer>

				<div style={{ display: 'none' }}>
					<PdfFile invoice={invoice} />
				</div>
			</Container>
		</>
	);
};
