import React, { createContext, useCallback, useContext, useState } from 'react';
import { api } from '../Services/api';

interface User {
  id: string;
  username: string;
}

interface LoginCredentials {
  username: string;
  password: string;
}

interface AuthContextData {
  token: string;
  user: User;
  login(credentials: LoginCredentials): Promise<void>;
  logout(): void;
  updateUser(user: User): void;
}

interface AuthState {
  token: string;
  user: User;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@LocastApp:token');
    const user = localStorage.getItem('@LocastApp:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const login = async ({ username, password }: LoginCredentials) => {
    const data = {
      username,
      password,
    };

    const response = await api.post('/sessions', data);

    const { token, user } = response.data;

    localStorage.setItem('@LocastApp:token', token);
    localStorage.setItem('@LocastApp:user', JSON.stringify(user));

    setData({ token, user });
  };

  const logout = async () => {
    localStorage.removeItem('@LocastApp:token');
    localStorage.removeItem('@LocastApp:user');

    setData({} as AuthState);
  };

  const updateUser = useCallback(
    async (user: User) => {
      localStorage.setItem('@LocastApp:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        user: data.user,
        login: login,
        logout: logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export { AuthContext, AuthProvider, useAuth };
