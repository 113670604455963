import { shade } from 'polished';
import styled, { css } from 'styled-components';
import { Colors } from '../../Styles/Colors';
import { Tooltip } from '../Tooltip/Tooltip';

interface ContainerProps {
	isFocused: boolean;
	isErrored: boolean;
	isDisabled: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	h4 {
		color: ${Colors.black};
		font-size: 1rem;
	}
`;

export const InnerContainer = styled.div<ContainerProps>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: ${Colors.white};
	border: 0.2rem solid ${Colors.gray};
	border-radius: 0.5rem;
	padding: 0 1rem 0 1rem;
	min-width: 100%;
	margin-top: 0.2rem;

	${(props) =>
		props.isErrored &&
		css`
			border-color: ${Colors.red};
		`}

	${(props) =>
		props.isFocused &&
		css`
			border-color: ${Colors.blue};
		`}

	${(props) =>
		props.isDisabled &&
		css`
			background-color: ${shade(0.5, Colors.white)};
			border-color: ${shade(0.5, Colors.white)};
		`}

	& + div {
		margin-top: 0.8rem;
	}

	input {
		width: 100%;
		background-color: transparent;
		border: none;
		color: ${Colors.black};
		font-size: 1.4rem;
		padding: 1rem 0 1rem 0;

		&::placeholder {
			color: ${Colors.gray};
		}

		:disabled {
			cursor: not-allowed;
			color: #c9c9c9;
		}
	}
`;

export const Error = styled(Tooltip)`
	height: 2rem;
	margin-left: 1rem;

	svg {
		margin: 0;
		cursor: normal;
	}

	span {
		background: ${Colors.red};
		color: ${Colors.white};

		&::before {
			border-color: ${Colors.red} transparent;
		}
	}
`;
