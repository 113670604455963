import React from 'react';
import {
	Redirect,
	Route as ReactDOMRoute,
	RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';
import { useAuth } from '../Hooks/AuthContext';

interface RouteProps extends ReactDOMRouteProps {
	isAuthenticated?: boolean;
	component: React.ComponentType;
}

export const Route: React.FC<RouteProps> = ({
	isAuthenticated = false,
	component: Component,
	...rest
}) => {
	const { user } = useAuth();

	return (
		<ReactDOMRoute
			{...rest}
			render={({ location }) => {
				if (!isAuthenticated) {
					return <Component />;
				} else {
					return !!user ? (
						<Component />
					) : (
						<Redirect
							to={{
								pathname: '/',
								state: { from: location },
							}}
						/>
					);
				}
			}}
		/>
	);
};
