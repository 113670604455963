import styled from 'styled-components';
import { Colors } from '../../Styles/Colors';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 4rem;
	align-items: center;
	justify-content: space-between;
	padding: 0 2rem 0 2rem;
	background-color: ${Colors.blue};
	border-radius: 0.4rem;

	div {
		display: flex;
		align-items: center;

		h1 {
			font-size: 1.6rem;
			color: ${Colors.white};

			& + h1 {
				margin-left: 2rem;
			}
		}

		h2 {
			font-size: 1.6rem;
			color: ${Colors.white};
		}
	}

	svg {
		width: 2.4rem;
		height: 2.4rem;
		color: ${Colors.white};
		transition: color 0.4s;
		cursor: pointer;

		:hover {
			color: ${Colors.yellow};
		}

		& + svg {
			margin-left: 2rem;
		}
	}

	:nth-child(odd) {
		background-color: ${Colors.yellow};

		svg {
			color: ${Colors.black};

			:hover {
				color: ${Colors.blue};
			}
		}

		div {
			h1 {
				color: ${Colors.black};
			}
		}
	}
`;
