import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  margin: 2rem 0;
  transform: translateX(-50%);
  overflow: hidden;
`;
