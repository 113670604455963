import styled from 'styled-components';
import { Colors } from '../../Styles/Colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	img {
		width: 772px;
		height: auto;
	}
`;

export const InvoiceNumber = styled.strong`
	position: absolute;
	top: 0;
	margin-top: 150px;
	margin-left: 592px;
	font-size: 24px;
	color: ${Colors.red};
`;

export const InformationsContainer = styled.div`
	position: absolute;
	top: 0;
	margin-top: 205px;
	margin-left: 34px;

	h1 {
		font-size: 20px;
		font-weight: normal;
		color: ${Colors.black};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		-webkit-line-clamp: 1; /* number of lines to show */
		-webkit-box-orient: vertical;
	}

	h2 {
		font-size: 20px;
		font-weight: normal;
		color: ${Colors.black};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: pre-wrap;
		-webkit-line-clamp: 7; /* number of lines to show */
		-webkit-box-orient: vertical;
	}
`;

export const ItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 702px;
	height: 252px;
	margin-top: 56px;
`;

export const RowContainer = styled.div`
	display: flex;
	width: 100%;
`;
