import React from 'react';
import { useTransition } from 'react-spring';
import { ToastMessage } from '../../../Hooks/ToastContext';
import { Toast } from '../Toast/Toast';
import { Container } from './ToastContainerStyle';

interface ToastContainerProps {
  messages: ToastMessage[];
}

export const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  const messagesWithTransitions = useTransition(
    messages,
    (message: ToastMessage) => message.id,
    {
      from: { top: '0%', opacity: 0 },
      enter: { top: '0%', opacity: 1 },
      leave: { top: '0%', opacity: 0 },
    },
  );

  return (
    <Container>
      {messagesWithTransitions.map(({ item, key, props }) => (
        <Toast key={key} style={props} message={item} />
      ))}
    </Container>
  );
};
