import { ValidationError } from 'yup';

interface ErrorData {
	response: {
		status: number;
		data: {
			message: string;
		};
	};
}

export const getErrorMessage = (error: ErrorData): string => {
	if (!error.response) {
		return 'Ocorreu um erro. Por favor, tente novamente.';
	}

	if (error.response.status === 500) {
		return 'Ocorreu um erro. Por favor, tente novamente.';
	}

	return error.response.data.message;
};

export const logError = (error: ErrorData) =>
	console.log(
		`===========//===========\n[ERROR]: ${error.response.data.message} (${error.response.status})\n===========//===========`,
	);

interface Errors {
	[key: string]: string;
}

export const getValidationErrors = (error: ValidationError): Errors => {
	const validationErrors: Errors = {};

	error.inner.forEach((e) => {
		validationErrors[e.path as string] = e.message;
	});

	return validationErrors;
};
