import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import Logo from '../../Assets/logo.png';
import { useAuth } from '../../Hooks/AuthContext';
import { useToast } from '../../Hooks/ToastContext';
import {
	getErrorMessage,
	getValidationErrors,
	logError,
} from '../../Utils/ErrorUtils';
import { Input } from '../Input/Input';
import {
	Container,
	InnerContainer,
	LoginContainer,
	LogoutContainer,
} from './HeaderStyle';
import * as Yup from 'yup';

interface LoginFormData {
	username: string;
	password: string;
}

export const Header: React.FC = () => {
	const [showLoginBox, setShowLoginBox] = useState(false);
	const [showLogoutButton, setShowLogoutButton] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const formRef = useRef<FormHandles>(null);
	const loginRef = useRef<HTMLDivElement>(null);
	const logoutRef = useRef<HTMLDivElement>(null);

	const history = useHistory();
	const { addToast } = useToast();
	const { login, logout, user } = useAuth();

	const handleToggleLogin = () => setShowLoginBox((state) => !state);

	const handleLogin = async (data: LoginFormData) => {
		try {
			formRef.current?.setErrors({});

			const schema = Yup.object().shape({
				username: Yup.string().required('Usuário obrigatório'),
				password: Yup.string().required('Senha obrigatória'),
			});

			await schema.validate(data, {
				abortEarly: false,
			});

			await login(data);

			history.go(0);
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				const errors = getValidationErrors(error);
				formRef.current?.setErrors(errors);
			} else {
				addToast({
					type: 'error',
					title: 'Erro na autenticação',
					description: getErrorMessage(error),
				});
			}
		}
	};

	const handleToggleLogout = () => setShowLogoutButton((state) => !state);

	const handleLogout = async () => {
		try {
			await logout();

			history.go(0);
		} catch (error) {
			logError(error);
		}
	};

	const handleClickOutside = (event: any) => {
		if (!loginRef.current?.contains(event.target)) {
			setShowLoginBox(false);
		}

		if (!logoutRef.current?.contains(event.target)) {
			setShowLogoutButton(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () =>
			document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<Container>
			<InnerContainer>
				<img src={Logo} alt="logo" onClick={() => history.push('/')} />

				<div onClick={!user ? handleToggleLogin : handleToggleLogout}>
					{!user ? (
						<h1>ENTRAR</h1>
					) : (
						<h1>{`OLÁ, ${user.username}!`}</h1>
					)}

					<IoMdArrowDropdown />
				</div>

				{showLoginBox && (
					<LoginContainer ref={loginRef}>
						<Form ref={formRef} onSubmit={handleLogin}>
							<Input
								name="username"
								label="Usuário"
								placeholder="Usuário"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
							/>
							<Input
								name="password"
								label="Senha"
								placeholder="Senha"
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>

							<button type="submit">Entrar</button>
						</Form>
					</LoginContainer>
				)}

				{showLogoutButton && (
					<LogoutContainer ref={logoutRef} onClick={handleLogout}>
						<h2>Sair</h2>
					</LogoutContainer>
				)}
			</InnerContainer>
		</Container>
	);
};
