import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Header } from './Components/Header/Header';
import AppProvider from './Hooks';
import GlobalStyle from './Styles/GlobalStyle';
import Routes from './Route';

const App = () => (
  <BrowserRouter>
    <AppProvider>
      <Header />
      <Routes />
    </AppProvider>

    <GlobalStyle />
  </BrowserRouter>
);

export default App;
