import React from 'react';
import { Provider } from 'react-redux';
import store from '../Store';
import { AuthProvider } from './AuthContext';
import { ToastProvider } from './ToastContext';

const AppProvider: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <ToastProvider>
        <AuthProvider>{children}</AuthProvider>
      </ToastProvider>
    </Provider>
  );
};

export default AppProvider;
