import { formatToBRL } from 'brazilian-values';
import { format, utcToZonedTime } from 'date-fns-tz';
import Fatura from '../../Assets/fatura.jpeg';
import { Invoice } from '../ListItem/ListItem';
import {
	Container,
	InformationsContainer,
	InvoiceNumber,
	ItemsContainer,
	RowContainer,
} from './PdfFileStyle';

interface PdfFileProps {
	invoice: Invoice;
}

export const PdfFile: React.FC<PdfFileProps> = ({ invoice }) => {
	return (
		<Container id="pdf">
			<img src={Fatura} alt="Fatura" />

			<InvoiceNumber>{invoice.number}</InvoiceNumber>

			<InformationsContainer>
				<RowContainer style={{ marginTop: 0 }}>
					<h1 style={{ width: 408 }}>{invoice.client}</h1>
					<h1 style={{ marginLeft: 18 }}>{invoice.cnpj}</h1>
				</RowContainer>

				<RowContainer style={{ marginTop: 26 }}>
					<h1 style={{ width: 480 }}>{invoice.address}</h1>
					<h1 style={{ marginLeft: 18 }}>{invoice.CEP}</h1>
				</RowContainer>

				<RowContainer style={{ marginTop: 26 }}>
					<h1 style={{ width: 248 }}>{invoice.city}</h1>
					<h1
						style={{
							width: 212,
							marginLeft: 22,
						}}
					>
						{invoice.state}
					</h1>
					<h1 style={{ marginLeft: 14 }}>
						{format(
							utcToZonedTime(
								invoice.issue_date,
								'Europe/Portugal',
							),
							'dd/MM/yyyy',
						)}
					</h1>
				</RowContainer>

				<ItemsContainer>
					{invoice.items &&
						invoice.items.map((item) => (
							<RowContainer style={{ marginTop: 10 }}>
								<h1
									style={{
										width: 512,
									}}
								>
									{item.description}
								</h1>
								<h1
									style={{
										marginLeft: 22,
										width: 168,
									}}
								>
									{formatToBRL(item.value)}
								</h1>
							</RowContainer>
						))}
				</ItemsContainer>

				<RowContainer style={{ marginTop: 50 }}>
					<h1 style={{ width: 172, textAlign: 'center' }}>
						{format(
							utcToZonedTime(invoice.due_date, 'Europe/Portugal'),
							'dd/MM/yyyy',
						)}
					</h1>
					<h1
						style={{
							width: 324,
							marginLeft: 22,
							textAlign: 'center',
						}}
					>
						{invoice.paymentConditions}
					</h1>
					<h1
						style={{
							marginLeft: 18,
							width: 172,
							textAlign: 'center',
						}}
					>
						{formatToBRL(invoice.totalValue)}
					</h1>
				</RowContainer>

				<h2
					style={{
						marginTop: 20,
						width: 702,
						height: 176,
					}}
				>
					{invoice.description}
				</h2>
			</InformationsContainer>
		</Container>
	);
};
