import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { parseISO } from 'date-fns/fp';
import React, { useRef, useState } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlus } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Input } from '../../Components/Input/Input';
import { useAuth } from '../../Hooks/AuthContext';
import { useToast } from '../../Hooks/ToastContext';
import { api } from '../../Services/api';
import viacepApi from '../../Services/viacepApi';
import { getErrorMessage, getValidationErrors } from '../../Utils/ErrorUtils';
import {
	AddItem,
	AddressContainer,
	CityContainer,
	Comments,
	Container,
	DatesContainer,
	InnerContainer,
	ItemContainer,
	ItemsTitle,
	NameContainer,
	NumberContainer,
	SubmitButton,
} from './CreateStyle';

interface ZipCodeData {
	cep: string;
	logradouro: string;
	complemento: string;
	bairro: string;
	localidade: string;
	uf: string;
	unidade: string;
	ibge: string;
	gia: string;
}

interface Item {
	description: string;
	value: string;
}

const MIN_CEP_DIGITS = 9;

export const Create = () => {
	const [zipCodeData, setZipCodeData] = useState({} as ZipCodeData);
	const [items, setItems] = useState<Item[]>([]);
	const [description, setDescription] = useState('');
	const [totalValue, setTotalValue] = useState(0);

	const formRef = useRef<FormHandles>(null);

	const { token } = useAuth();
	const { addToast } = useToast();

	const history = useHistory();

	const handleChangeCEP = async (value: string) => {
		if (value.length === MIN_CEP_DIGITS) {
			try {
				formRef.current?.setErrors({});

				const cleanCEP = value.replace('-', '');
				const response = await viacepApi.get(`/${cleanCEP}/json`);

				if (!!response.data.erro) {
					return formRef.current?.setErrors({
						cep: 'CEP inválido',
					});
				}

				setZipCodeData(response.data);
			} catch (error) {}
		}
	};

	const handleChangeItem = (item: Item, index: number) => {
		const newItems = items;
		newItems[index] = item;
		setItems([...newItems]);
	};

	const handleRemoveItem = (index: number) => {
		const newItems = items;
		newItems.splice(index, 1);
		setItems([...newItems]);
	};

	const handleSubmit = async (formData: any) => {
		try {
			formRef.current?.setErrors({});

			const schema = Yup.object().shape({
				client: Yup.string().required('Cliente obrigatório'),
				cnpj: Yup.string().required('CNPJ obrigatório'),
				cep: Yup.string().min(9, 'CEP inválido'),
				issue_date: Yup.date().required('Data de emissão inválida'),
				due_date: Yup.date().required('Data de vencimento inválida'),
				totalValue: Yup.number().positive('Valor obrigatório'),
			});

			await schema.validate(
				{ ...formData, totalValue },
				{
					abortEarly: false,
				},
			);

			const data = {
				address: formData.address,
				CEP: formData.cep,
				city: formData.city,
				client: formData.client,
				cnpj: formData.cnpj,
				issue_date: parseISO(formData.issue_date),
				due_date: parseISO(formData.due_date),
				paymentConditions: formData.paymentConditions,
				state: formData.state,
				totalValue,
				items,
				description,
				number: formData.number,
			};

			await api.post('/invoices', data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			addToast({
				type: 'success',
				title: 'Sucesso',
				description: 'Nota editada com sucesso!',
			});

			history.push('/');
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				const errors = getValidationErrors(error);
				formRef.current?.setErrors(errors);
			} else {
				addToast({
					type: 'error',
					title: 'Erro',
					description: getErrorMessage(error),
				});
			}
		}
	};

	return (
		<Container>
			<Form ref={formRef} onSubmit={handleSubmit}>
				<InnerContainer>
					<NameContainer>
						<Input
							name="client"
							label="Nome da Empresa"
							placeholder="Nome da Empresa"
						/>

						<Input
							name="cnpj"
							label="CNPJ"
							placeholder="CNPJ"
							mask="99.999.999/9999-99"
						/>
					</NameContainer>

					<AddressContainer>
						<Input
							name="cep"
							label="CEP"
							placeholder="CEP"
							onChangeText={handleChangeCEP}
							mask="99999-999"
						/>

						<Input
							name="address"
							label="Endereço"
							placeholder="Endereço"
							value={zipCodeData.logradouro}
							onChange={(value) => {
								setZipCodeData({
									...zipCodeData,
									logradouro: value.target.value,
								});
							}}
						/>
					</AddressContainer>

					<CityContainer>
						<Input
							name="city"
							label="Cidade"
							placeholder="Cidade"
							value={zipCodeData.localidade}
							onChange={(value) => {
								setZipCodeData({
									...zipCodeData,
									localidade: value.target.value,
								});
							}}
						/>

						<Input
							name="state"
							label="Estado"
							placeholder="Estado"
							value={zipCodeData.uf}
							onChange={(value) => {
								setZipCodeData({
									...zipCodeData,
									uf: value.target.value,
								});
							}}
						/>
					</CityContainer>

					<DatesContainer>
						<Input
							name="issue_date"
							label="Data de Emissão"
							placeholder="Data de Emissão"
							type="date"
						/>

						<Input
							name="due_date"
							label="Data de Vencimento"
							placeholder="Data de Vencimento"
							type="date"
						/>
					</DatesContainer>

					<DatesContainer>
						<Input
							name="paymentConditions"
							label="Condições de Pagamento"
							placeholder="Condições de Pagamento"
						/>

						<Input
							name="totalValue"
							label="Valor Total"
							placeholder="R$ 0,00"
							onChangeText={(value) =>
								setTotalValue(parseFloat(value))
							}
							isMoney
						/>
					</DatesContainer>

					{items.length ? <ItemsTitle>Itens</ItemsTitle> : <></>}

					{items.map((item, key) => (
						<ItemContainer key={key}>
							<Input
								name={`description-${key}`}
								label="Descrição"
								placeholder="Descrição"
								value={item.description}
								onChange={(value) =>
									handleChangeItem(
										{
											description: value.target.value,
											value: item.value,
										},
										key,
									)
								}
							/>

							<Input
								name={`value-${key}`}
								label="Valor"
								placeholder="R$ 0,00"
								value={item.value}
								onChangeText={(value) =>
									handleChangeItem(
										{
											description: item.description,
											value,
										},
										key,
									)
								}
								isMoney
							/>

							<AiOutlineMinusCircle
								onClick={() => handleRemoveItem(key)}
							/>
						</ItemContainer>
					))}

					{items && items.length < 7 ? (
						<AddItem
							onClick={() =>
								setItems((state) => [
									...state,
									{ description: '', value: '' },
								])
							}
						>
							<h2>Adicionar item</h2>
							<AiOutlinePlus />
						</AddItem>
					) : (
						<></>
					)}

					<Comments
						placeholder="Observações"
						onChange={(value) => setDescription(value.target.value)}
					/>

					<NumberContainer>
						<Input
							name="number"
							label="Número da Fatura"
							placeholder="0000"
						/>
					</NumberContainer>

					<SubmitButton type="submit">Salvar</SubmitButton>
				</InnerContainer>
			</Form>
		</Container>
	);
};
