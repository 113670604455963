import React from 'react';
import Loader from 'react-loader-spinner';
import { Colors } from '../../Styles/Colors';
import { Container } from './LoadingStyle';

interface LoadingProps {
	loading: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ loading }) => {
	return (
		<Container loading={loading}>
			<Loader type="Oval" color={Colors.blue} height={100} width={100} />
		</Container>
	);
};
