import { shade } from 'polished';
import styled from 'styled-components';
import { Colors } from '../../Styles/Colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 2rem;

	div {
		display: flex;
		align-items: center;
		background-color: ${Colors.blue};
		padding: 1.2rem;
		border: none;
		border-radius: 0.4rem;
		transition: all 0.2s;
		cursor: pointer;

		:hover {
			background-color: ${shade(0.2, Colors.blue)};
		}

		:active {
			background-color: ${shade(0.4, Colors.blue)};
		}

		& + div {
			margin-left: 0.8rem;
		}

		svg {
			width: 2.2rem;
			height: 2.2rem;
			stroke-width: 2rem;
			color: ${Colors.white};
		}

		h1 {
			font-size: 1.6rem;
			font-weight: normal;
			color: ${Colors.white};
			margin-left: 0.8rem;
			user-select: none;
		}
	}
`;

export const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	img {
		width: 772px;
		height: auto;
		margin-top: 16px;
	}
`;

export const InvoiceNumber = styled.strong`
	position: absolute;
	top: 0;
	margin-top: 213px;
	margin-left: 592px;
	font-size: 24px;
	color: ${Colors.red};
`;

export const InformationsContainer = styled.div`
	position: absolute;
	top: 0;
	margin-top: 268px;
	margin-left: 34px;

	h1 {
		font-size: 20px;
		font-weight: normal;
		color: ${Colors.black};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		-webkit-line-clamp: 1; /* number of lines to show */
		-webkit-box-orient: vertical;
	}

	h2 {
		font-size: 20px;
		font-weight: normal;
		color: ${Colors.black};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: pre-wrap;
		-webkit-line-clamp: 7; /* number of lines to show */
		-webkit-box-orient: vertical;
	}
`;

export const ItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 702px;
	height: 252px;
	margin-top: 56px;
`;

export const RowContainer = styled.div`
	display: flex;
	width: 100%;
`;
