import styled from 'styled-components';
import { Colors } from '../../Styles/Colors';

export const Container = styled.div`
	display: flex;
	width: 100%;
	height: 8rem;
	position: relative;
	justify-content: center;
	background-color: #ffffff;
	box-shadow: 0 0.8rem 2rem -0.8rem black;
`;

export const InnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 112rem;
	height: 100%;
	align-items: center;
	position: relative;

	> img {
		height: 6rem;
		cursor: pointer;
	}

	> div {
		position: absolute;
		right: 0;
		display: flex;
		align-items: center;
		cursor: pointer;

		> h1 {
			font-size: 1.6rem;
			color: #777777;
			transition: color 0.4s;
			user-select: none;
		}

		> svg {
			color: #777777;
			width: 2.4rem;
			height: 2.4rem;
		}

		:hover {
			> h1 {
				color: #1f5eaf;
			}

			> svg {
				color: #1f5eaf;
			}
		}
	}
`;

export const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	background-color: ${Colors.yellow};
	align-items: center;
	justify-content: center;
	padding: 1.6rem;
	border-radius: 0.4rem;
	top: 80%;
	box-shadow: 0.4rem 0.8rem 0.8rem -0.8rem black;

	form {
		position: relative;
		width: 20rem;

		div {
			& + div {
				margin-top: 0.8rem;
			}
		}

		> button {
			background-color: #1f5eaf;
			border: none;
			padding: 0.8rem 1.6rem 0.8rem 1.6rem;
			margin-top: 1.2rem;
			border-radius: 0.4rem;
			font-size: 1.4rem;
			font-weight: bold;
			color: #ffffff;
		}
	}
`;

export const LogoutContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: absolute;
	background-color: #666666;
	align-items: center;
	justify-content: center;
	padding: 1.6rem;
	border-radius: 0.4rem;
	top: 80%;
	box-shadow: 0.4rem 0.8rem 0.8rem -0.8rem black;

	> h2 {
		padding: 0 1.6rem 0 1.6rem;
		font-size: 1.4rem;
		font-weight: bold;
		user-select: none;
		color: #ffffff;
		transition: color 0.2s;
		cursor: pointer;

		:hover {
			color: ${Colors.yellow};
		}
	}

	> svg {
		width: 2rem;
		height: 2rem;
		color: #ffffff;
		transition: color 0.2s;
	}
`;
