import React, { useState } from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { BiEditAlt, BiTrash } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../Hooks/AuthContext';
import { useToast } from '../../Hooks/ToastContext';
import { api } from '../../Services/api';
import { getErrorMessage } from '../../Utils/ErrorUtils';
import { InvoicePdf } from '../InvoicePdf/InvoicePdf';
import { Container } from './ListItemStyle';

export interface Invoice {
	id: string;
	number: number;
	client: string;
	cnpj: string;
	address: string;
	CEP: string;
	city: string;
	state: string;
	items: Item[];
	paymentConditions: string;
	totalValue: number;
	description: string;
	issue_date: string;
	due_date: string;
	created_at: Date;
	updated_at: Date;
}

export interface Item {
	description: string;
	value: number;
}

interface ListItemProps {
	invoice: Invoice;
	onDelete: () => void;
}

export const ListItem: React.FC<ListItemProps> = ({ invoice, onDelete }) => {
	const [showPdf, setShowPdf] = useState(false);

	const history = useHistory();

	const { token } = useAuth();
	const { addToast } = useToast();

	const handleEdit = () =>
		history.push({
			pathname: '/edit',
			state: {
				invoice,
			},
		});

	const handleDelete = async () => {
		if (
			window.confirm(
				`Você deseja excluir a nota fiscal da empresa ${invoice.client}?`,
			)
		) {
			try {
				await api.delete('/invoices', {
					headers: {
						Authorization: `Bearer ${token}`,
					},
					data: {
						id: invoice.id,
					},
				});

				addToast({
					type: 'success',
					title: 'Sucesso',
					description: 'Nota fiscal excluida com sucesso!',
				});

				onDelete();
			} catch (error) {
				addToast({
					type: 'error',
					title: 'Erro',
					description: getErrorMessage(error),
				});
			}
		}
	};

	return showPdf ? (
		<InvoicePdf invoice={invoice} onCancel={() => setShowPdf(false)} />
	) : (
		<Container>
			<div>
				<h1>{invoice.number}</h1>
				<h1>{invoice.client}</h1>
			</div>

			<div>
				<AiOutlineFilePdf onClick={() => setShowPdf(true)} />
				<BiEditAlt onClick={handleEdit} />
				<BiTrash onClick={handleDelete} />
			</div>
		</Container>
	);
};
